.MessageContainer {
    margin-top: 0px;
}

.MessageMatchesProfilePicture {
    width: 85px;
    height: 85px;
}

.MessageHolder {
    display: grid;
    grid-template-columns: 25% 74.5%;	
}

.MessageHolderContext {
    height: 69vh;
    overflow-y: auto; 
    border-top: 1px solid hsl(0, 0%, 22%);
    border-radius: 5px;
}

.MessageMatches {
    border-bottom: 1px solid hsl(0, 0%, 22%);
    border-radius: 5px;
    padding: 5px;
    display: flex;
    cursor: pointer;
    gap: 15px;
}

.MessageMatchesContextName {
    font-weight: bold;
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 20px;
    color: hsl(0, 0%, 53%);
}

.MessageMatchesContextMessage {
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 17px;
    font-weight: normal;
    color: hsl(0, 1%, 34%);
    position: relative;
    top: 5px;
}

.NoMessages {
    font-weight: bold;
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 30px;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    top: 45%;
    color: hsl(0, 0%, 53%);
}

@media only screen and (max-width: 845px) {
    .MessageContainer {
        position: absolute;
        top: 35vh;
    }

    .NoneSelectedMessageHolder, .SelectedMessageHolder {
        width: 100vw;
        height: 70vh;
        grid-template-columns: none;
    }

    .SelectedMessageHolder .MessageHolderContext {
        display: none;
    }

    .NoneSelectedMessageHolder .MessageBox {
        display: none;
    }

    .SelectedMessageHolder .MessageBox {
        height: 75vh;
    }
}
