.MessageBoxContent {
    border-top: 1px solid hsl(0, 0%, 22%);
    margin-top: 20px;
}

.NoMessages {
    color: hsl(0, 0%, 53%);
}

.Content {
    max-height: 67vh;
    overflow-y: auto;
    display: grid;
    grid-template-rows: repeat(auto-fill, minmax(8.9%, 10.5%));
    gap: 10px;
}

.Content::-webkit-scrollbar {
    width: 0px;
}

.divideTheySentYouSent {
    margin-bottom: 10px;
}

@media only screen and (max-width: 845px) {
    .Content {
        width: 98vw;
        height: 80vh;
        grid-template-rows: repeat(auto-fill, minmax(10.2%, 10.5%));
    }
}
