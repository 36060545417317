.profile-page .profile-container {
    width: 80vw;
    margin-top: 2vh;
    margin-right: auto;
    margin-left: auto;
}

.profile-page .profile-container .profile-edit-container {
    width: 50vw;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.profile-page .profile-container .profile-edit-container .profilePicture {
    width: 10vw;
    border-radius: 50%;
}

.profile-page .profile-container .profile-edit-container .side-container {
    display: flex;
    flex-direction: column;
}

.profile-page .profile-container .profile-edit-container .side-container .edit-u-b {
    display: flex;
    flex-direction: row;
    gap: 2vw;
}

.profile-page .profile-container .profile-edit-container .side-container .username {
    font-size: 28px;
    color: white;
}

.profile-page .profile-container .profile-edit-container .side-container .editButton {
    font-size: 20;
    text-decoration: none;
    background: #FF3636;
    padding: 10px;
    border-radius: 10px;
    color: white;
}

.profile-page .profile-container .profile-edit-container .side-container .data-rows {
    display: flex;
    flex-direction: row;
    gap: 2vw;
    margin-top: 2vh;
    color: white;
}

.profile-page .profile-container .profile-edit-container .side-container .bio {
    color: white;
    font-size: 16px;
    margin-top: 2vh;
}

.profile-page .profile-container .viewSelectors {
    width: 20vw;
    margin-top: 5vh;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-bottom: 1px solid #FF3636;
}

.profile-page .profile-container .viewSelectors .viewSelect {
    cursor: pointer;
    margin-bottom: 10px;
}

.profile-page .profile-container .viewSelectors .viewSelect.selected {
    border-bottom: 1px solid #FF3636;
    margin-bottom: 2vh;
}

.profile-page .profile-container .viewSelectors .viewSelect-bookmark {
    position: relative;
    top: 0.4vh;
}

.profile-page .profile-container .reel-container {
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.profile-page .profile-container .reel-container .reel-row-container {
    display: flex;
    flex-direction: row;
    gap: 2vw;
}

.profile-page .profile-container .reel-container .reel-row-container .reel-container {
    width: 50px;
    height: 50px;
}
