.UnMatchedPeersContainer {
    border-radius: 5px;
    overflow-x: auto;
    overflow-y: none;
    width: 99.8%;
    height: 140px;
}

.UnMatchedPeersContainerContext {
    color: red;
    font-family: "IM FELL Great Primer SC", serif;
    margin-top: 10px;
    display: flex;
}

.UnMatchedContain {
    overflow-y: none;
}

.UnMatchedPeerContain {
    height: 100px;
    overflow-y: none;
    display: flex;
    flex-direction: row;
}

.UnMatchedPeersBlured {
    position: absolute;
    top: 130px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: rgba(255, 104, 104, 0.26);
    border-radius: 10px;
    width: 98.5vw;
    height: 90px;
}

.UnMatchedPeersBluredContextLink {
    position: absolute;
    top: 1.5vh;
    left: 45%;
    background: white;
    border-radius: 10px;
    padding: 10px;
    text-decoration: none;
    color: black;
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 30px;
}

.UnMatchedPeersContainerContext .circle {
    height: 5px;
    width: 5px;
    display: inline-block;
    border-radius: 50%;
    padding: 10px;
    text-align: center;
    background-color: red;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -5px;
    left: -9vw;
}

.UnMatchedContainProfilePicture {
    height: 70px;
    width: 70px;
}

.UnMatchedPeersContainerContext, .UnMatchedPeers {
    margin-left: 10px;
}

@media only screen and (max-width: 845px) {
    .UnMatchedPeersBluredContextLink {
        position: absolute;
        font-size: 20px;
        left: 32%;
    }

    .UnMatchedPeersContainer {
        position: absolute;
        z-index: -1;
    }

    .UnMatchedPeersBlured {
        position: absolute;
        height: 15vh;
        top: 6vh;
    }
}
