.AcceptedClientContainer {
    margin-top: 0px;
}

.AcceptedClientContainer .AcceptedClientContext {
    color: red;
    font-family: "IM FELL Great Primer SC", serif;
    margin-top: 10px;
    display: flex;
}

.AcceptedClientContainer .AcceptedClientContext .circle {
    align-items: center;
    background-color: red;
    border-radius: 50%;
    color: white;
    display: flex;
    height: 5px;
    justify-content: center;
    left: -9vw;
    padding: 10px;
    position: relative;
    text-align: center;
    top: -5px;
    width: 5px;
}

.AcceptedClientContainer .profile-pic {
    cursor: pointer;
    padding: 5px;
    width: 85px;
    height: 85px;
    border: 1px solid red;
    border-radius: 30px;
}

.AcceptedClientContainer .ClientHolder {
    display: grid;
    grid-template-columns: 25% 74.5%;
}

.AcceptedClientContainer .ClientHolder .ClientHolderContext {
    height: 69vh;
    overflow-y: auto; 
    border-top: 1px solid hsl(0, 0%, 22%);
    border-radius: 5px;
}

.AcceptedClientContainer .ClientHolder .ClientHolderContext .NoClients {
    font-weight: bold;
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 30px;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    color: hsl(0, 0%, 53%);
    top: 45%;
}

.AcceptedClientContainer .ClientHolder .ClientHolderContext .Client {
    border-bottom: 1px solid hsl(0, 0%, 22%);
    border-radius: 5px;
    padding: 5px;
    display: flex;
    cursor: pointer;
    gap: 15px;
    text-decoration: none;
}

.AcceptedClientContainer .ClientHolder .ClientHolderContext .Client .client-name {
    font-weight: bold;
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 20px;
    color: hsl(0, 0%, 53%);
}

.AcceptedClientContainer .ClientHolder .ClientHolderContext .Client .client-message {
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 17px;
    font-weight: normal;
    color: hsl(0, 1%, 34%);
    position: relative;
    top: 5px;
}

@media only screen and (max-width: 845px) {
    .ClientHolder.active, .ClientHolder.unactive {
        width: 100vw;
        height: 70vh;
        grid-template-columns: none;
    }

    .ClientHolder.active .ClientHolderContext {
        display: none;
    }

    .ClientHolder.active .ClientBox  {
        height: 75vh;
    }

    .ClientHolder.unactive .ClientBox {
        display: none;
    }
}
