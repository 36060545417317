.SearchResultVisualDiv {
    border-radius: 4px;
    padding: 10px;
    display: border;
}

.UserSearchResult {
    border: 1px solid rgb(36, 35, 35);
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 1%;
    margin-bottom: 10px;
    width: 50vw;
    margin-right: auto;
    margin-left: auto;
}

.UserSearchResult .name, .UserSearchResult .bio {
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 20px;
}

.name {
    font-weight: bold;
    color: rgb(56, 56, 56);
}

.center {
    margin-top: 2.5%;
}

.centerB {
    margin-top: 2%;
}

.UserSearchResult .circle {
    height: 25px;
    width: 25px;
    display: inline-block;
    border-radius: 50%;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

}

.UserSearchResult .X {
    font-size: 30px;
    font-family: "IM FELL Great Primer SC", serif;
    color: white;
}

.UserSearchResult .X:hover {
    color: rgb(255, 155, 5);
}

.UserSearchResult .Heart {
    color: white;
    font-size: 30px;
    position: relative;
    bottom: 0.8vh;
}

.UserSearchResult .Heart:hover {
    color: #fe005d;
}

.UserSearchResult .X, .UserSearchResult .Heart {
    cursor: pointer;
}

.NoSearchResult {
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 20px;
    color: #e36b97;
    text-align: center;
    background: white;
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
}

.SearchResultBasicSubscriptionBlured {
    position: absolute;
    top: 17vh;
    right: 0.5vw;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(1px);
    background: rgba(255, 104, 104, 0.548);
    width: 99vw;
    height: 80vh;
    border-radius: 10px;
    padding: 10px;
}

.SearchResultBasicSubscriptionBluredContextLink {
    position: relative;
    top: 25%;
    left: 45%;
    background: white;
    border-radius: 10px;
    padding: 10px;
    text-decoration: none;
    color: black;
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 30px;
}

@media only screen and (max-width: 845px) {
    .SearchResult {
        z-index: -1;
        position: relative;
    }

    .SearchResultBasicSubscriptionBlured {
        top: 8vh;
    }

    .SearchResultBasicSubscriptionBluredContextLink {
        left: 25vw;
    }
}