.ProfilePicture {
    padding: 5px;
    cursor: pointer;
}

.ProfilePictureImage {
    border-radius: 30px;
    border: 1px solid red;
    width: 100px;
    height: 100px;
}