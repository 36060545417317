.PeerMatch {
    width: 70%;
    margin-top: 1%;
    margin-left: auto;
    margin-right: auto;
}

.PeerMatchImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-left: 31.3%;
    width: -moz-fit-content;
    width: fit-content;
}

.PeerMatchDetailsContainer {
    background: hsl(0, 1%, 13%);
    width: 28vw;
    height: 25vh;
    margin-left: 31.2%;
    position: relative;
    top: -1vw;
}

.circle {
    height: 30px;
    width: 100vw;
    margin-left: 10%;
    display: inline-block;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    background-color: hsl(0, 0%, 22%);
}

.PeerMatchX {
    font-size: 20px;
    font-family: "IM FELL Great Primer SC", serif;
    color: white;
}

.PeerMatchHeart {
    background: #FF3636;
    color: white;
    align-items: center;
    justify-content: center;
}

.PeerMatchHeart:hover {
    color: #fe005d;
}

.PeerMatchX:hover {
    color: rgb(255, 155, 5);
}

.PeerMatchActionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    width: 55%;
}

.PeerImages {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 28vw;
    height: 50vh;
}

.PeerMatchDetails {
    position: relative;
    top: 1vw;
    width: 28vw;
    height: 52px;
    text-align: left;
}

.PeerMatchDetailsName {
    font-size: 30px;
    font-weight: bold;
    margin-top: 1%;
    position: relative;
    left: -3vw;
    color: white;
    text-align: center;
    width: 100%;
}

.PeerMatchDetailsBio {
    font-size: 20px;
    margin-top: 1%;
    position: relative;
    left: -1vw;
    font-weight: bold;
    font-style: italic;
    color: rgb(138, 138, 138);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    border-bottom: 0.5px solid #363434;
}

.PeerMatchDetailsName, .PeerMatchDetailsBio {
    margin-left: 10%;
    font-family: "IM FELL Great Primer SC", serif;
} 

@media only screen and (max-width: 845px) {
    .PeerMatch {
        width: 61vw;
        position: absolute;
        top: 20vh;
        z-index: -1
    }

    .PeerImages {
        width: 60vw;
        height: 48vh;
    }

    .PeerMatchDetails {
        width: 61vw;
        top: 2vh;
        right: 1vw;
        height: 10vh;
    }

    .PeerMatchDetailsContainer {
        width: 59vw;
        height: 23vh;
        position: relative;
        top: -2vh;
    }

    .PeerMatchDetailsName {
        width: 59vw;
        text-align: center;
        left: -5vw;
    }

    .PeerMatchActionContainer {
        margin-left: 12vw;
        gap: 10vw;
    }

    .circle {
        height: 4vh;
    }

    .PeerMatchHeart .anticon-heart {
        position: relative;
        top: -0.5vh;
    }
}
