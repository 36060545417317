.MessageBox {
    border: 1px solid hsl(0, 0%, 22%);
    border-radius: 5px;
    height: 69vh;
}

.MessageBoxLoading {
    position: absolute;
}

.MessageBoxEmpty {
    width: -moz-fit-content;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    top: 45%;
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 20px;
    font-weight: bold;
    color: hsl(0, 0%, 53%);
}

.MessageBoxContainer {
    display: grid;
    grid-template-rows: 7% 88% 5%;
    height: 69vh;
}

.MessageBoxDetails {
    display: flex;
    gap: 10px;
}

.MessageBoxDetailsProfilePicture {
    width: 40px;
    height: 40px;
}

.MessageBoxDetailsName {
    font-weight: bold;
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 20px;
    position: relative;
    top: 10px;
    color: hsl(0, 0%, 53%);
}

.MessageBoxInput {
    border: 1px solid hsl(0, 0%, 22%);
    border-radius: 10px;
    padding: 5px;
    display: grid;
    grid-template-columns: 2% 95% 3%;
}

.MessageBoxEmojiPicker {
    position: relative;
    top: 3.5px;
    font-size: 20px;
    color: rgb(48, 132, 241);
}

.MessageBoxSendButton {
    font-size: 20px;
    border: 1px solid hsl(0, 0%, 22%);
    border-radius: 10px;
    margin-left: 5px;
    background: rgb(48, 132, 241);
    color: white;
    cursor: pointer;
}

.MessageBoxTextInput {
    border: none;
    overflow: auto;
    resize: none;
    background: black;
    color: white;
}

.MessageBoxTextInput:focus {
    outline: none;
}

.MessageBoxSendButton:hover {
    background: rgb(15, 117, 250);
}

@media only screen and (max-width: 845px) {
    .MessageBoxEmojiPicker {
        display: none;
    }

    .MessageBoxInput {
        grid-template-columns: 85% 15%;
        z-index: 4;
    }

    .MessageBoxContainer {
        grid-template-rows: 7% 85% 6%;
    }
}
