.WatchTrainerVideoPage .VideoContainer {
    width: 98vw;
    height: 70vh;
    margin-right: auto;
    margin-left: auto;
}

.WatchTrainerVideoPage .splitter {
    display: grid;
    grid-template-columns: 65vw 33vw;
}

.WatchTrainerVideoPage .detailsContainer {
    width: 58vw;
}

.WatchTrainerVideoPage .detailsContainer .title {
    color: rgb(120, 120, 120);
    font-size: 25px;
    margin-bottom: 2vh;
}

.WatchTrainerVideoPage .detailsContainer .details {
    color: rgb(75, 75, 75);
    font-size: 20px;
    display: flex;
    gap: 2vw;
    margin-bottom: 20px;
}

.WatchTrainerVideoPage .detailsContainer .details div {
    display: flex;
    height: 4.5vh;
}


.WatchTrainerVideoPage .detailsContainer .details .name-client-sub {
    flex-direction: row;
    gap: 10px;
}

.WatchTrainerVideoPage .detailsContainer .details .name-client-sub .profile {
    width: 4.5vw;
    height: 5vh;
}

.WatchTrainerVideoPage .detailsContainer .details .name-client-sub .trainer-name {
    cursor: pointer;
}

.WatchTrainerVideoPage .detailsContainer .details .name-client-sub .sub {
    background: rgb(12, 12, 12);
    color: hsl(0, 0, 22%);
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
    height: 2.5vh;
}

.WatchTrainerVideoPage .detailsContainer .details .name-client-sub .sub:hover {
    background: rgb(31, 30, 30);
    color: rgb(107, 106, 106);
}

.WatchTrainerVideoPage .detailsContainer .details .name-client-sub div {
    flex-direction: column;
}

.WatchTrainerVideoPage .detailsContainer .details div img {
    border-radius: 20px;
}

.WatchTrainerVideoPage .detailsContainer .details .buttons {
    gap: 2vw;
    width: 20vw;
}

.WatchTrainerVideoPage .detailsContainer .details .buttons .icon {
    cursor: pointer;
}

.WatchTrainerVideoPage .detailsContainer .details .buttons .likeDislike {
    display: flex;
    width: 15vw;
    gap: 2px;
    border: 1px solid rgb(85, 85, 85);
    background: hsl(0, 0%, 7%);
    border-radius: 10px;
    padding: 5px;
    font-size: 17px;
    color: rgb(69, 69, 69);
}

.WatchTrainerVideoPage .detailsContainer .details .buttons .likeDislike.none {
    width: 5vw;
    justify-content: center;
}

.WatchTrainerVideoPage .detailsContainer .details .buttons .likeDislike .holder {
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;
}

.WatchTrainerVideoPage .detailsContainer .details .buttons .likeDislike .holder.like {
    border-right: 1px solid rgb(85, 85, 85);
    width: 55%;
}

.WatchTrainerVideoPage .detailsContainer .details .buttons .likeDislike.none .holder.like {
    border: none;
}

.WatchTrainerVideoPage .detailsContainer .details .buttons .likeDislike .holder span {
    position: relative;
    top: 0.4vh;
}

.WatchTrainerVideoPage .detailsContainer .description {
    color: hsl(0, 1%, 26%);
    background: rgb(16, 16, 16);
    border-radius: 10px;
    padding: 10px;
    margin-right: auto;
    margin-left: auto;
}

.WatchTrainerVideoPage .detailsContainer .description .views-date {
    color: rgb(158, 158, 158);
}

.WatchTrainerVideoPage .vidRecommends .video-rec {
    margin-bottom: 5vh;
    cursor: pointer;
    color: hsl(0, 1%, 26%);
}

.WatchTrainerVideoPage .vidRecommends .video-rec a {
    text-decoration: none;
    color: hsl(0, 1%, 26%);
}

.WatchTrainerVideoPage .vidRecommends .video-rec .vidInfor {
    width: 20vw;
}

.WatchTrainerVideoPage .vidRecommends .video-rec .vidInfor .thumbnail {
    width: 30vw;
    height: 15vw;
    border-radius: 10px;
}

.WatchTrainerVideoPage .vidRecommends .video-rec .vidInfor .duration {
    position: relative;
    top: -5.5vh;
    background: rgb(20, 20, 20);
    color: rgb(150, 150, 150);
    border-radius: 10px;
    padding: 5px;
}

.WatchTrainerVideoPage .vidRecommends .video-rec .vidInfor .duration.min {
    float: right;
    left: 9.8vw;
}

.WatchTrainerVideoPage .comments {
    margin-top: 10px;
    border-top: 1px solid rgb(94, 94, 94);
}

.WatchTrainerVideoPage .comments .comment-count {
    color: rgb(100, 99, 99);
    margin-top: 10px;
}

.WatchTrainerVideoPage .comments .post-comment {
    margin-top: 10px;
}

.WatchTrainerVideoPage .comments .post-comment .comment-input {
    display: flex;
}

.WatchTrainerVideoPage .comments .post-comment .profile-pic {
    border-radius: 50%;
    position: relative;
    height: 50px;
}

.WatchTrainerVideoPage .comments .post-comment textarea {
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgb(63, 61, 61);
    color: rgb(112, 111, 111);
}

.WatchTrainerVideoPage .comments .post-comment textarea:focus {
    outline: none;
}

.WatchTrainerVideoPage .comments .post-comment textarea::-webkit-scrollbar {
    background: rgb(36, 35, 35);
}

.WatchTrainerVideoPage .comments .post-comment textarea::-webkit-scrollbar-thumb {
    background: rgb(75, 75, 75);
    border-radius: 10px;
}

.WatchTrainerVideoPage .comments .post-comment .comment-buttons {
    display: flex;
    float: right;
    margin-top: 10px;
    gap: 3vw;
}

.WatchTrainerVideoPage .comments .post-comment .comment-buttons button {
    border-radius: 10px;
    padding: 10px;
    background: #FF3636;
    color: white;
    cursor: pointer;
    border: 1px solid #FF3636;
}

.WatchTrainerVideoPage .comments .post-comment .comment-buttons button.cancel {
    background: rgb(29, 29, 29);
    border: 1px solid rgb(29, 29, 29);
    color: rgb(143, 143, 143);
}

.WatchTrainerVideoPage .comments-container {
    position: relative;
    top: 2vh;
}

.WatchTrainerVideoPage .comments-container .showmore {
    color: rgb(116, 114, 114);
    background: rgb(27, 27, 27);
    border-radius: 10px;
    padding: 10px;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 30px;
    cursor: pointer;
}

.WatchTrainerVideoPage .comments-container .showmore:hover {
    background: rgb(56, 56, 56);
    color: rgb(139, 138, 138);
}

.WatchTrainerVideoPage .comments-container .comment {
    display: flex;
    width: 57.8vw;
    overflow-x: none;
    color: rgb(71, 71, 71);
    margin-bottom: 40px;
}

.WatchTrainerVideoPage .comments-container .comment .profile-pic {
    border-radius: 50px;
    height: 4.5vh;
    width: 5vw;
}

.WatchTrainerVideoPage .comments-container .comment .name-date {
    display: flex;
}

.WatchTrainerVideoPage .comments-container .comment .name-date .name {
    color: rgb(95, 93, 93);
}

.WatchTrainerVideoPage .comments-container .comment .com {
    width: 54vw;
}

.WatchTrainerVideoPage .comments-container .comment .com .comment-children .childrenDesc {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}

.WatchTrainerVideoPage .comments-container .comment .com .comment-children .childrenDesc .button {
    background:hsl(0, 0%, 7%);
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.WatchTrainerVideoPage .comments-container .comment .com .comment-children .comment-buttons button {
    width: 5vw;
    text-align: center;
    display: block;
    height: 4vh;
}

.WatchTrainerVideoPage .comments-container .comment .com .comment-children .comment-buttons button.cancel {
    width: 3.5vw;
}

.WatchTrainerVideoPage .comments-container .comment .com .comment-children .children-container {
    margin-top: 15px;
}

@media only screen and (max-width: 845px) {
    .WatchTrainerVideoPage .VideoContainer {
        width: 97vw;
    }

    .WatchTrainerVideoPage .splitter {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .WatchTrainerVideoPage .detailsContainer {
        width: 97vw;
    }

    .WatchTrainerVideoPage .vidRecommends .video-rec {
        margin-bottom: 2vh;
        width: 70vw;
        margin-right: auto;
        margin-left: auto;
    }

    .WatchTrainerVideoPage .vidRecommends .video-rec .vidInfor .thumbnail {
        width: 70vw;
        height: 20vh;
    }

    .WatchTrainerVideoPage .vidRecommends .video-rec .vidInfor .duration {
        left: 58vw;
    }

    .WatchTrainerVideoPage .vidRecommends .video-rec .vidInfor .duration.min  {
        left: 48vw;
    }

    .WatchTrainerVideoPage .vidRecommends .video-rec .bottom .desc {
        width: 70vw;
        text-decoration: none;
        white-space: nowrap;
    }

    .WatchTrainerVideoPage .vidRecommends .video-rec .bottom .dets {
        width: 70vw;
        text-decoration: none;
        white-space: nowrap;
    }
}
