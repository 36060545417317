.TrainersSummary {
    border: 1px solid #1d1d1d;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    width: 50vw;
    margin-right: auto;
    margin-left: auto;
}

.TrainersSummary .Header {
    display: flex;
    align-items: center;
    gap: 40px;
    border-bottom: 1px solid #1d1d1d;
}

.TrainersSummary .Header img {
    width: 100px;
    height: 70px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.TrainersSummary .Header .dividerContainer {
    font-size: 40px;
    margin-bottom: 10px;
}

.TrainersSummary .Header .dividerContainer .name-price {
    display: flex;
    gap: 15px;
}

.TrainersSummary .Header .dividerContainer .price {
    color: rgb(6, 252, 6);
}

.TrainersSummary .bio {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
}

@media only screen and (max-width: 845px) {
    .TrainersSummary {
        width: 90vw;
    }

    .TrainersSummary .Header .dividerContainer {
        font-size: 50px;
    }

    .TrainersSummary .Header .dividerContainer .price {
        position: relative;
        left: 2vw;
    }
}
