.NavBar {
    border-bottom: 1px solid #3a3939;
    padding: 10px;
    margin: 5px;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
}

.NavBarContainer {
    width: 100%;
    float: right;
    display: flex;
    gap: 15%;
    justify-content: space-between;
    align-items: center;
}

.NavBarSearch {
    margin: auto;
    position: relative;
    top: 20%;
    left: 12%;
    cursor: pointer;
}

.NavBarLottie {
    cursor: pointer;
}

.NavBarItem {
    margin-right: 10%;
    cursor: pointer;
}

.NavBarProfilePicture {
    display: flex;
}

.ProfilePictureHoverContent {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.hidden {
    display: none;
}

.vissible {
    display: flex;
}

.NavBarProfileImage {
    width: 50px;
    height: 50px;
}

.NavBarContainerMobile {
    display: none;
    z-index: 5;
}

@media only screen and (max-width: 845px) {
    .NavBarContainer {
        display: none;
    }

    .NavBarSearch {
        margin: auto;
        position: relative;
        top: 2vh;
        left: 7vw;
        cursor: pointer;
        width: 56vw;
    }

    .NavBarContainerMobile {
        display: flex;
        gap: 5%;
        justify-content: space-between;
        align-items: center;
    }

    .NavBarContainerMobileDropDownMenu {
        background: red;
        color: white;
        border-radius: 10px;
        padding: 10px;
        font-size: 15px;
    }

    .NavBarContainerMobileDropDownContent {
        position: absolute;
        max-width: 20vw;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 5;
        border: 1px solid #3a3939;
        border-radius: 10px;
        padding: 8px;
    }
}
