.YouSentMessage, .TheySentMessage {
    width: -moz-fit-content;
    width: fit-content;
    max-width: 30vw;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    border: 0.5px solid #3e3e3e;
}

.TheySentMessage {
    background: black;
    color: white;
}

.YouSentMessage {
    background: rgb(29, 134, 253);
    color: white;
    float: right;
}

.MessageViewDivDidSee::first-letter {
    text-transform: capitalize;
}

@media only screen and (max-width: 845px) {
    .MessageViewDiv {
        z-index: -1;
    }
}
